import { useRouter } from 'next/router'
import AuthLayout from '@/layouts/auth'
import withI18n from '@/utils/withTranslations'
import { ReactElement } from 'react'
import { FormLogin } from '@/src/components/forms'
import { CardWhenMobile } from '@/utils/renderers'
import { AlertTriangle } from '@untitled-ui/icons-react'
import { t } from '@lingui/macro'

const Login = () => {
  const router = useRouter()
  const { error } = router.query

  return (
    <CardWhenMobile>
      <FormLogin />
      {error === '' + 'no_orgs' && (
        <div
          className={
            'bg-warning-100 px-4 py-2 rounded-sm flex flex-row gap-4 justify-center items-center'
          }>
          <AlertTriangle className={'text-warning-500'} width={'18'} />{' '}
          <p
            className={
              'text-caption text-neutral-700'
            }>{t`Your account does not have access to any organization`}</p>
        </div>
      )}
    </CardWhenMobile>
  )
}

Login.getLayout = function getLayout(page: ReactElement) {
  return <AuthLayout>{page}</AuthLayout>
}

export const getServerSideProps = withI18n(ctx => {
  return {
    props: { translation: ctx.translation, locale: ctx.locale }
  }
})

export default Login
